@media (max-width: 767px) {
  .header-snackbar .content {
    font-size: 14px;
    order: 2;
  }

  .header-snackbar {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px !important;
  }

  .icon_welcome {
    order: 0;
  }

  .close_welcome_alert {
    order: 1;
  }
}

.dashboard-container {
  min-height: calc(100vh - 114px);
  margin-top: 1rem;
}

.activityCard {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiCardContent-root {
  flex: 1;
}

.image {
  max-height: 120px;
}

.no-event-msg {
  margin-top: 5.5rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.header-snackbar {
  background-color: #f6f6f6;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 1rem 0;
}

.content {
  flex-grow: 1;
}

.snackbar-link {
  color: #009fb7;
  cursor: pointer;
}

.remove_product_msg {
  position: sticky;
  top: 62px;
  z-index: 1;
  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
  font-size: 0.875rem;
  border-radius: 6px;
  padding: 15px 10px;
}

.product-status {
  position: absolute;
  top: -20px;
  right: 15px;
  padding: 4px 21px 4px 7px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 19px;
  border-radius: 1.25rem;
}
.product-status .circle {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 5px;
}
.test-status {
  background-color: #fff0da;
  color: #f59403;
  border: 1px solid #f59403;
}
.inactive-status {
  background-color: #dcdcdc;
  color: #343434;
  border: 1px solid #343434;
}
.paid-status {
  background-color: #d1faf6;
  color: #16b096;
  border: 1px solid #16b096;
}

.manage-btn {
  margin-left: 0.5rem;
}

.card-wrapper {
  width: 33.33% !important;
  margin: 0.5rem 0 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.card-box-pad {
  padding: 2rem 8px;
}
.add-prd-btn {
  margin: 0 auto;
  padding: 2rem 0;
}
.or-divider {
  margin: 1rem auto;
  text-align: center;
}
.or-divider span:nth-child(2) {
  border: 2px solid #0000001f;
  border-radius: 50%;
  padding: 4px 5px;
}
.or-divider span:nth-child(1)::before {
  color: #0000001f;
  content: '\2003\2003\2003\2003\2003\2003';
  text-decoration: line-through;
}
.or-divider span:nth-child(3)::before {
  color: #0000001f;
  content: '\2003\2003\2003\2003\2003\2003';
  text-decoration: line-through;
}
.card-wrapper .btn-secondary,
.name-form .btn-secondary {
  border: 1px solid #ff7200;
}
.invite-form {
  padding: 0.5rem 1rem;
}
.name-form input {
  border: 1px solid #202020;
}
.name-modal-heading {
  font-size: 2rem;
  font-weight: normal;
}

.create-slideshow-form h3 {
  font-size: 1.5rem;
}
.create-slideshow-form .error {
  font-size: 12px;
}
.cancel-btn {
  background-color: #fff;
  color: rgb(255, 114, 0);
  padding: 6px 20px;
  font-size: 0.875rem;
  text-transform: uppercase;
  border: 0;
}
.container-header {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #d2d2d2;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}
.container .add-event-btn {
  background-color: #009fb7;
  color: #fff;
  border: 0;
  /* position: fixed;
  bottom: 50px;
  right: 10px; */
  font-size: 1rem;
  width: auto;
  border-radius: 1.25rem;
  padding: 8px 30px;
}

.membership-pros-list {
  list-style-type: none;
}
.payment-modal__web-logo {
  border-bottom: 1px solid #b5b5b5;
  width: 300px;
}
.payment-modal__success-msg p {
  font-size: 2rem;
  margin-top: 1rem;
}
.payment-modal__payment-receive-msg {
  font-size: 1rem;
  margin: 2.18rem 0 2.8rem;
}

.dashboard-qr-comp .mt-1rem {
  margin-top: 1rem;
}
.dashboard-qr-comp .txt-center {
  text-align: center;
}
.qr-img-container {
  margin: 0 auto;
  width: 63px;
}
.qr-content .warning-bar {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
}
.qr-content .warning-bar img {
  height: 1.5rem;
  width: 1.5rem;
}
.qr-heading {
  font-size: 1.25rem;
}
.flex-grow-1 {
  flex-grow: 1;
}
.sec-btn {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #009fb7;
  font-weight: 700;
}
.blue-tick {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 992px) {
  .card-wrapper {
    width: 50% !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

@media screen and (max-width: 770px) {
  .card-wrapper {
    width: 100% !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .container-header {
    justify-content: center;
  }
}

.container .toggle-radio .switch {
  height: 28px;
}
.container .toggle-radio .slider:before {
  left: 4px;
  bottom: 4px;
}
.container .toggle-radio input:checked + .slider:before {
  left: 0 !important;
}
