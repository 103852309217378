.flex {
  display: flex;
}
.event-details {
  flex-basis: 50%;
  flex-direction: column;
  align-items: center;
}
.poster-comp {
  transform: scale(0.3);
  transform-origin: top left;
  position: absolute;
}
.poster-container {
  border: 1px solid black;
  padding: 3rem 2rem;
  cursor: pointer;
}
/* @media print {
	@page {
		size: landscape;
	}
} */
.poster-top-section {
  margin-left: 2rem;
  justify-content: space-between;
}
.poster-top-section p {
  color: #202020;
  font-size: 8px;
}
.event-dtl-wrap {
  flex-direction: column;
}
.app-logo {
  width: 15rem;
  /* width:20rem; */
  height: 4rem;
  object-fit: contain;
}
.activity-code-header {
  text-align: center;
  margin: 2rem 0 1rem;
}
.activity-code {
  border: 2px solid #d2d2d2;
  letter-spacing: 4px;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
}
.banner-image {
  width: 20rem;
}
.poster-bottom-section {
  justify-content: space-between;
  padding-top: 2rem;
}
/* ol {
	list-style: none;
}

li {
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
} */

/* .numbering {
	color: #ffffff;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	position: absolute;
	font-size: 1.1rem;
	width: 1.6rem;
	height: 1.6rem;
}
.list-text {
	margin-left: 2rem;
} */
.divider-wrapper {
  justify-content: center;
}

.or-separator {
  flex-direction: column;
  align-items: center;
}
.or-text {
  border: 1px solid black;
  border-radius: 50%;
  padding: 1px 3px 1.5px;
}

.vertical-line {
  border-left: 1px solid #707070;
  flex-grow: 1;
  width: 1px;
}
.qr-container {
  text-align: center;
}
.qr-code {
  width: 15rem;
}
.poster-divider {
  border: 1px solid #d2d2d2;
  margin-top: 1rem;
}
.qr-code-img {
  width: 13rem;
  margin-top: 1rem;
}
.qr-poster {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.qr-poster .app-logo {
  width: 50%;
  margin-bottom: 1rem;
  height: 3rem;
  object-fit: contain;
}
.qr-poster .poster-activiy-code {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 4px;
  text-align: center;
}
.qr-code-txt {
  padding: 1rem;
  font-size: 1.2rem;
  background-color: #ff7200;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}
.qr-poster .qr-code-txt {
  margin: 0.5rem 0;
}
.qr-poster .qr-code-img {
  width: 35%;
}
.single-poster .app-logo {
  width: 40%;
  margin-top: 1rem;
  height: 4.5rem;
  object-fit: contain;
}
.qr-subtext {
  font-size: 2rem;
  padding: 1rem 3rem;
  border-top: 2px solid #b5b5b5;
  margin-top: 0.5rem;
}
.multiple-posters .app-logo {
  width: 60%;
  height: 1.8rem;
  object-fit: contain;
}
.multiple-posters .qr-subtext {
  font-size: 1rem;
  padding: 0.5rem 3rem;
}
.multiple-posters .qr-code-txt,
.multiple-posters .poster-activiy-code {
  font-size: 1rem;
}
.multiple-posters .qr-code-img {
  width: 40%;
  margin-top: 0;
}
.container-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    '. . .'
    '. . .';
}
.grid-borders {
  border: 1px solid black;
  padding-top: 1rem;
}
/* .grid-borders .; */

.downloads-cards {
  /* width: 300px;
    height: 200px; */
  box-shadow: 0px 2px 5px #00000029;
  border: 1px solid #e5e5e5;
  flex-basis: 20%;
  padding: 1rem 0;
}
.downloads-cards img {
  transform: rotate(180deg);
}
.download-cards-wrap {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-input {
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  position: relative;
  min-height: 3.5rem;
  justify-content: space-between;
  padding: 0 1rem;
  width: 15rem;
  margin-bottom: 0.5rem;
}
.logo-input input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
}
.event-logo {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 550px) {
  .download-cards-wrap {
    justify-content: center;
  }
}
