.games-activities-wrapper {
  min-height: calc(100vh - 130px);
}

.test-drive-btn {
  margin-left: 0.5rem !important;
}
.activity-card {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.disable-activity-page-scroll {
  height: calc(100vh - 130px);
  overflow: hidden;
}

.invite-code-inp {
  width: 100%;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  border: 1px solid #d2d2d2;
  margin: 2rem 0 1rem;
}

.moderator-card {
  padding: 5rem 1rem;
}
