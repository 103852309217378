.left-arrow {
  width: 15px;
  margin-left: -2px;
}
.common-qr-container {
  margin: 20px auto;
  max-width: 1440px;
  padding: 0 20px;
  min-height: calc(100vh - 99px);
}
.page-h3 {
  font-size: 26px;
  font-weight: 500;
}

.breadcrumb {
  margin-top: 5px;
}
.breadcrumb span:nth-child(1) {
  color: rgb(0, 159, 183);
}
.breadcrumb span:nth-child(2) {
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
}
.breadcrumb span:nth-child(3) {
  color: rgb(112, 112, 112);
}
.common-qr-page-comp .text-center {
  text-align: left;
}
.common-qr-page-comp .qr-img-container {
  width: 300px;
  margin: 0;
}
.common-qr-page-comp .qr-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 3rem;
  margin-top: 1rem;
}
@media screen and (max-width: 580px) {
  .common-qr-page-comp .qr-img-container {
    width: 200px;
  }
}

@media (min-width: 576px) {
  .common-qr-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .common-qr-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .common-qr-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .common-qr-container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .common-qr-container {
    max-width: 1440px;
  }
}
