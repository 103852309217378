.download-card-main .qr-content li {
  position: relative;
  margin-left: 20px;
  font-size: 14px;
}
/* .download-card-main .qr-content li::before {
	position: absolute;
	content: "";
	background-color: #000000;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
} */
.downloads-wrapper {
  display: flex;
  margin: 1.5rem auto 2rem;
  gap: 1rem;
  flex-wrap: wrap;
  padding-top: 2rem;
  border-top: 1px solid #e2e2e2;
}

.downloads-card-wrapper {
  box-shadow: 0px 2px 5px #00000029;
  width: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  padding: 1rem 0;
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.download-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.download-card .card-icon {
  max-height: 161px;
  width: 100%;
}
.print-btn {
  display: flex;
  gap: 10px;
}
.downloads-card-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.downloads-card-btn {
  border: none;
  outline: none;
  background-color: #099647;
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  gap: 0.5rem;
  cursor: pointer;
}

.send-photos-btn {
  text-transform: uppercase;
  padding: 0.5rem 2.5rem;
  font-size: 14px;
  border-radius: 20px;
}
.canva-link {
  color: #551a8b;
}
.canva-link:visited {
  color: #551a8b;
}
.canva-link:active {
  color: #551a8b;
}

.single-qr-code {
  display: none;
}

.download-pdf-btn {
  background-color: #099647;
  color: #fdfdfd;
  border: 0;
  padding: 0.5rem 3rem;
  border-radius: 1.25rem;
}

.get-all-data-form input {
  font-size: 1rem;
  padding: 16.5px 14px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  max-width: 350px;
}

.event-logo {
  margin-bottom: 1rem;
  width: 20rem;
}

@media screen and (max-width: 550px) {
  .downloads-cards {
    margin: 0 auto;
  }
}
