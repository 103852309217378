.members-page-container {
  padding: 0 12px;
}
.assets-btn-wrapper {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #d2d2d2;
}
.assets-btn-wrapper a {
  cursor: pointer;
  text-decoration: none;
}
.assets-btn-wrapper button {
  width: 198px;
  height: 40px;
  background-color: #009fb7;
  color: #fff;
  border-radius: 20px;
  border: 0;
  outline: 0;
}
.assets-btn-wrapper a:visited {
  color: #fff;
}

.assets-btn-wrapper a:hover {
  color: #fff;
}

.assets-btn-wrapper a:active {
  color: #fff;
}

.user-credit-plan-wrapper {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #d2d2d2;
}
.user-credit-plan-wrapper .heading-1 {
  font-size: 25px;
  font-weight: 500;
}
.mb-5rem {
  margin-bottom: 5rem;
}
.mb-1rem {
  margin-bottom: 1rem;
}
.user-credit-plan {
  border: 2px solid #d2d2d2;
  padding: 3rem 1rem;
  border-radius: 8px;
  box-shadow: 0px 2px 5px #00000017;
  position: relative;
}
.user-credit-plan .plan-heading {
  font-size: 25px;
  font-weight: 500;
}
.price-label {
  font-size: 20px;
}
.user-credit-plan button {
  width: 198px;
  height: 40px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 20px;
  border: 0;
  outline: 0;
  font-size: 1rem;
  text-transform: uppercase;
}
.item-price {
  font-weight: 400;
  font-size: 3rem;
}

.fs-1_25rem {
  font-size: 1.25rem;
}

.copy-link-text {
  color: #009fb7;
  font-weight: 600;
  font-size: 1.1rem;
}
.copy-link-text img {
  width: 22px;
}

.ribbon-wrapper {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, 0);
}
.ribbon1 {
  background-color: #f6b11b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
  width: fit-content;
  padding: 4px 10px;
}
.ribbon2 {
  background-color: #049849;
  border-radius: 5px;
  color: #fafafa;
  width: max-content;
  font-weight: 600;
  padding: 2px 50px 8px;
}
.ribbon2 span {
  font-weight: 800;
  font-size: 22px;
}

.row-gap-4rem {
  row-gap: 4rem !important;
}
.common-slideshow-config-card .app-pri-btn {
  padding: 10px 30px;
}
.common-slideshow-config-card .app-sec-btn {
  color: #202020;
  font-weight: 600;
}
.number-options {
  background-color: #eeeeee;
  border-radius: 50%;
  font-size: 1.5rem;
  height: 5rem;
  width: 5rem;
}
.selected-number-option {
  background-color: #ff7200;
  color: #fff;
}
.p-1rem {
  padding: 1rem;
}
.mb-24px {
  margin-bottom: 24px;
}
.credits-balance {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 4px;
  width: fit-content;
}
.credits-balance span:not(:last-child):not(:first-child)::after {
  content: ',  ';
}

.ribbon {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #049849;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  color: #fafafa;
}

@media screen and (max-width: 770px) {
  .card-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
}
